import { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Link from "next/link";
import axios from "axios";
import BlogTiles from "../../components/blogTiles";
import BlogPhotoTile from "../../components/BlogTilePhoto";
import { useRouter } from "next/router";
import webengage from "@/webengage";
import { cn } from "@/lib/utils";
import mixpanel from "@/utils/mixpanel";
import Head from "next/head";
import { ArrowLeftIcon } from "lucide-react";
import Breadcrumb from "@/components/BreadCrumb";

export async function getServerSideProps() {
  try {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_API}blogs?live=true`,
      {
        headers: { "Accept-Encoding": "gzip,deflate,compress" },
      }
    );

    const spiritualityId = "clcj0zqjl000ogr0qnev4m235";

    const { data: fetchedSubCategories } = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_API}categories/sub-categories/${spiritualityId}`,
      {
        headers: { "Accept-Encoding": "gzip,deflate,compress" },
      }
    );
    return {
      props: { blogs: data, tags: fetchedSubCategories?.data },
    };
  } catch (err) {
    console.error("Error fetching blogs:", err);
    return {
      props: {
        blogs: [],
        tags: [],
      },
    };
  }
}

type Tag = { [key: string]: { name: string; id: string; slug: string } };

export const calculateReadingTime = (content: string): number => {
  const wordsPerMinute = 200;
  const words = content.split(/\s+/).length;
  const readingTime = Math.ceil(words / wordsPerMinute);
  return readingTime;
};

export default function Blogs({ blogs, tags }: { blogs: any; tags: any }) {
  const router = useRouter();
  const [discoverBlogs, setDiscoverBlogs] = useState<any[]>([]);
  const [popularBlogs, setPopularBlogs] = useState<any[]>([]);
  const [activeCategory, setActiveCategory] = useState<{
    name: string;
    id: string;
    slug: string;
  } | null>(null);

  const tagsArray = tags || [];
  const TAGS: Tag = {};
  tagsArray.forEach((tag: any) => {
    TAGS[tag.slug] = {
      name: tag?.name,
      id: tag?.id,
      slug: tag?.slug,
    };
  });

  useEffect(() => {
    const temp = [...blogs];
    temp.sort((a: any, b: any) => b.views - a.views);
    setPopularBlogs(temp);
  }, [activeCategory, blogs]);

  useEffect(() => {
    const temp = [...blogs];
    const filteredBlogs = activeCategory
      ? temp.filter((blog: any) => {
          return blog.categoryId === activeCategory.id;
        })
      : temp;

    filteredBlogs.sort(() => Math.random() - 0.5);
    setDiscoverBlogs(filteredBlogs);
  }, [blogs, activeCategory]);

  return (
    <>
      <Head>
        <title>
          Astrology Blogs | Explore Tips, Insights & Predictions | AstroLearn
        </title>
        <meta
          name="description"
          content="Discover insightful astrology blogs on predictions, healing, and manifestation. Stay updated with tips and expert advice for your spiritual journey."
        />
      </Head>
      <Layout>
        <main className="max-w-[1440px] mx-auto p-4 lg:p-8 font-lato mb-20 bg-transparent">
          <div className="flex gap-1 md:hidden">
            <ArrowLeftIcon
              className="w-6 h-6 mt-0.5"
              onClick={() => router.back()}
            />
            <span className="text-[18px] text-black font-lato font-[600] w-full text-left inline">
              Blogs
            </span>
          </div>
          <section className="my-6 lg:mt-0 lg:mb-16 w-full">
            <Breadcrumb />
            <h2 className="text-2xl lg:text-4xl font-bold mt-4">
              Popular Articles
            </h2>

            <div className="flex items-center justify-start space-x-4 md:space-x-6 overflow-x-auto w-full no-scrollbar xl:justify-start mt-4 lg:mt-6">
              {popularBlogs.length > 0 ? (
                popularBlogs.map((blog: any) => (
                  <BlogPhotoTile
                    key={blog.slug}
                    imgSrc={blog.coverImage}
                    title={
                      blog?.title && blog?.title.length > 18
                        ? blog?.title.slice(0, 18) + "..."
                        : blog?.title
                    }
                    minutesRead={calculateReadingTime(blog.content)}
                    slug={blog?.slug}
                  />
                ))
              ) : (
                <p className="text-xl">No blogs found</p>
              )}
            </div>
          </section>

          <section className="flex flex-col gap-5 w-full">
            <div className="flex lg:flex-row flex-col items-start lg:items-center lg:space-x-[240px] justify-between w-full">
              <p className="text-2xl lg:text-4xl font-bold">Discover</p>
              <div className="max-md:w-full md:max-w-[900px] flex gap-3 justify-center max-lg:mt-3 lg:justify-end">
                <button
                  onClick={() => setActiveCategory(null)}
                  className={cn(
                    "rounded-full whitespace-nowrap text-sm md:text-[15px] px-3 md:px-3.5 py-1 md:py-1.5 font-medium capitalize border-none",
                    !activeCategory ? "bg-[#FFD700]" : "bg-[#9A702633]"
                  )}
                >
                  All
                </button>
                {tagsArray?.map((tag: any) => (
                  <button
                    onClick={() => {
                      const _webengage = webengage();
                      if (_webengage) {
                        _webengage.track("Category Clicked", {
                          industry: tag.name,
                          source: "Astrolearn",
                        });
                      }
                      setActiveCategory(tag);
                    }}
                    className={cn(
                      "rounded-full text-sm md:text-[15px] px-3 md:px-3.5 py-1 md:py-1.5 font-medium capitalize border-none whitespace-nowrap",
                      tag?.name === activeCategory?.name
                        ? "bg-[#FFD700]"
                        : "bg-[#9A702633]"
                    )}
                    key={tag?.name}
                  >
                    {tag?.name}
                  </button>
                ))}
              </div>
            </div>

            <div className="flex flex-col lg:grid lg:grid-cols-2 lg:mt-6 gap-8">
              {discoverBlogs.length > 0 ? (
                discoverBlogs.map((blog: any) => (
                  <Link
                    href={`/blogs/${blog?.slug}`}
                    className="hover:no-underline hover:bg-transparent rounded-lg"
                    key={blog?.slug}
                    onClick={() =>
                      mixpanel.track("blog_card_click", {
                        blog: blog?.slug,
                      })
                    }
                  >
                    <BlogTiles
                      blog={blog}
                      minutesRead={calculateReadingTime(blog.content)}
                    />
                  </Link>
                ))
              ) : (
                <p className="text-xl">No blogs found</p>
              )}
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
}
